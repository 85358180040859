<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiSelectProps {
  layout?: 'simple' | 'standard';
  options: Readonly<any[]>;
  value: any;
}
const props = withDefaults(defineProps<CiSelectProps>(), {
  layout: 'standard',
});

const emit = defineEmits<{
  change: [value: string];
}>();

const styles = {
  simple: 'bg-transparent text-base font-medium',
  standard: 'bg-white rounded border border-primary',
};

/********************
 * FUNCTIONS         *
 ********************/
function onChange($event: Event) {
  emit('change', $event.target.value);
}
</script>

<template>
  <select
    class="ci-select relative h-10 cursor-pointer appearance-none bg-no-repeat py-0 pl-3.5 pr-8 text-sm text-primary outline-none"
    :class="styles[props.layout]"
    style="
      background-image: linear-gradient(45deg, transparent 50%, #54c100 50%),
        linear-gradient(135deg, #54c100 50%, transparent 50%);
      background-position:
        calc(100% - 20px) 50%,
        calc(100% - 15px) 50%;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    "
    :value="props.value?.key"
    @change="onChange"
  >
    <option
      v-for="option in props.options"
      :key="option.key"
      :value="option.key"
      :selected="option.key === props.value.key"
    >
      {{ option.value }}
    </option>
  </select>
</template>

<style></style>
